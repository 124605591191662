<template>
    <div>
        <el-dialog
                :title="title"
                width="90%"
                :close-on-click-modal="false"
                :append-to-body="true"
                :visible.sync="visible" class="dialogForm dialogTable">
            <el-form size="small" :inline="true" ref="searchForm" :model="searchForm">
                <el-form-item prop="loginName" label="手机号：">
                    <el-input size="small" v-model.trim="searchForm.loginName" maxlength="11" placeholder="请输入手机号"
                              clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="refresh()" size="small" icon="el-icon-search">查询
                    </el-button>
                    <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置
                    </el-button>
                </el-form-item>
            </el-form>
            <el-row :gutter="10">
                <el-col :span="20">
                    <el-table
                            :data="dataList"
                            v-loading="loading"
                            size="small"
                            ref="userTable"
                            @selection-change="selectionChangeHandle"
                            @sort-change="sortChangeHandle"
                            height="calc(100vh - 310px)"
                            style="width: 100%;">
                        <el-table-column
                                header-align="center"
                                align="center"
                                v-if="limit <= 1"
                                width="50">
                            <template slot-scope="scope">
                                <el-radio :label="scope.row.id"
                                          :value="dataListAllSelections[0]&&dataListAllSelections[0].id"
                                          @change.native="getTemplateRow(scope.$index,scope.row)"><span></span>
                                </el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column
                                type="selection"
                                header-align="center"
                                v-if="limit > 1"
                                align="center"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="image"
                                header-align="center"
                                align="center"
                                label="头像">
                            <template slot-scope="scope">
                                <img :src="scope.row.image === ''?'/static/img/defaultAvatar.png':scope.row.image"
                                     style="height:35px"/>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="mobilePhone"
                                sortable="custom"
                                min-width="100px"
                                label="手机号">
                            <template slot-scope="scope">
                                {{ scope.row.mobilePhone | specialCharacter }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                min-width="100px"
                                sortable="custom"
                                label="姓名">
                        </el-table-column>
                        <el-table-column
                                prop="email"
                                sortable="custom"
                                min-width="100px"
                                show-overflow-tooltip
                                label="邮箱">
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageSize"
                            :total="total"
                            background=""
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </el-col>
                <el-col :span="4">
                    <el-tag
                            :key="tag.id"
                            v-for="tag in dataListAllSelections"
                            closable
                            :disable-transitions="false"
                            @close="del(tag)">
                        {{tag.name}}
                    </el-tag>
                </el-col>
            </el-row>
            <div slot="footer" class="text_center">
                <el-button size="small" @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="doSubmit()">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                searchForm: {
                    loginName: '',
                    company: {
                        id: ''
                    },
                    office: {
                        id: ''
                    },
                    name: '',
                    roleId: 0,
                },
                filterText: '',
                dataListAllSelections: [],   // 所有选中的数据包含跨页数据
                dataListSelections: [],
                idKey: 'id', // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
                dataList: [],
                dynamicTags: [],
                officeTreeData: [],
                pageNo: 1,
                pageSize: 10,
                total: 0,
                orderBy: '',
                loading: false,
                visible: false
            }
        },
        props: {
            selectData: {
                type: Array,
                default: () => {
                    return []
                }
            },
            title: {
                type: String,
                default: () => {
                    return '用户选择'
                }
            },
            limit: {
                type: Number,
                default: 999999
            }
        },
        watch: {
            filterText(val) {
                // this.$refs.officeTree.filter(val)
            }
        },
        methods: {
            init(roleId) {
                this.visible = true
                this.refreshTree()
                //角色列表；排除角色已分配过的用户
                if (undefined != roleId) {
                    this.$nextTick(() => {
                        this.$refs.userTable.clearSelection()
                        this.$refs.searchForm.resetFields()
                        this.searchForm.roleId = roleId
                        this.dataListAllSelections = JSON.parse(JSON.stringify(this.selectData))
                        this.refreshRoleList()
                    })
                } else {
                    this.$nextTick(() => {
                        this.$refs.userTable.clearSelection()
                        this.$refs.searchForm.resetFields()
                        this.dataListAllSelections = JSON.parse(JSON.stringify(this.selectData))
                        this.resetSearch()
                    })
                }
            },
            renderContent(h, {node, data, store}) {
                return (
                    < span
            class
                = "custom-tree-node" >
                    {
                        data.type === '1' ? < i
            class
                = "fa fa-sitemap" > < /i> : <i class="fa fa-users"></i >
            }
            <
                span
            class
                = "text" > {node.label} < /span>
                    < /span>
            )
            },
            getTemplateRow(index, row) {                                 // 获取选中数据
                this.dataListSelections = [row]
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 设置选中的方法
            setSelectRow() {
                if (!this.dataListAllSelections || this.dataListAllSelections.length <= 0) {
                    this.$refs.userTable.clearSelection()
                    return
                }
                // 标识当前行的唯一键的名称
                let idKey = this.idKey
                let selectAllIds = []
                this.dataListAllSelections.forEach(row => {
                    selectAllIds.push(row[idKey])
                })
                this.$refs.userTable.clearSelection()
                for (var i = 0; i < this.dataList.length; i++) {
                    if (selectAllIds.indexOf(this.dataList[i][idKey]) >= 0) {
                        // 设置选中，记住table组件需要使用ref="table"
                        this.$refs.userTable.toggleRowSelection(this.dataList[i], true)
                    }
                }
            },
            // 记忆选择核心方法
            changePageCoreRecordData() {
                // 标识当前行的唯一键的名称
                let idKey = this.idKey
                let that = this
                // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
                if (this.dataListAllSelections.length <= 0) {
                    this.dataListSelections.forEach(row => {
                        that.dataListAllSelections.push(row)
                    })
                    return
                }
                // 总选择里面的key集合
                let selectAllIds = []
                this.dataListAllSelections.forEach(row => {
                    selectAllIds.push(row[idKey])
                })
                let selectIds = []
                // 获取当前页选中的id
                this.dataListSelections.forEach(row => {
                    selectIds.push(row[idKey])
                    // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
                    if (selectAllIds.indexOf(row[idKey]) < 0) {
                        that.dataListAllSelections.push(row)
                    }
                })
                let noSelectIds = []
                // 得到当前页没有选中的id
                this.dataList.forEach(row => {
                    if (selectIds.indexOf(row[idKey]) < 0) {
                        noSelectIds.push(row[idKey])
                    }
                })
                noSelectIds.forEach(id => {
                    if (selectAllIds.indexOf(id) >= 0) {
                        for (let i = 0; i < that.dataListAllSelections.length; i++) {
                            if (that.dataListAllSelections[i][idKey] === id) {
                                // 如果总选择中有未被选中的，那么就删除这条
                                that.dataListAllSelections.splice(i, 1)
                                break
                            }
                        }
                    }
                })
            },
            // 得到选中的所有数据
            getAllSelectionData() {
                // 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
                this.changePageCoreRecordData()
            },
            filterNode(value, data) {
                if (!value) return true
                return data.name.indexOf(value) !== -1
            },
            del(tag) {
                this.dataListAllSelections.splice(this.dataListAllSelections.indexOf(tag), 1)
                this.$nextTick(() => {
                    this.setSelectRow()
                })
            },
            refresh() {
                this.currentChangeHandle(1)
            },
            // 获取数据列表
            refreshList() {
                if (this.searchForm.roleId != 0) {
                    this.refreshRoleList();
                } else {
                    this.loading = true
                    this.$axios(this.api.auth.sysuserQueryAllList, {
                        'current': this.pageNo,
                        'size': this.pageSize,
                        'orderBy': this.orderBy,
                        ...this.searchForm
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.dataList = data.data.records
                            this.total = parseInt(data.data.total)
                            this.loading = false
                        }
                        this.$nextTick(() => {
                            this.setSelectRow()
                        })
                    })
                }
            },
            // 获取数据列表
            refreshRoleList() {
                this.loading = true
                this.$axios(this.api.auth.sysuserQueryRoleUserList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'orderBy': this.orderBy,
                    ...this.searchForm
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.dataList = data.data.records
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                    this.$nextTick(() => {
                        this.setSelectRow()
                    })
                })
            },
            refreshTree() {
                /*this.$axios(this.api.sys.officeTreeData, {}, 'get').then(data => {
                    this.officeTreeData = data.treeData
                })*/
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                this.refreshList()
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                this.refreshList()
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
                this.$nextTick(() => {
                    this.changePageCoreRecordData()
                })
            },
            // 排序
            sortChangeHandle(obj) {
                if (obj.prop === 'office.name') {
                    obj.prop = 'o.name'
                }
                if (obj.prop === 'company.name') {
                    obj.prop = 'c.name'
                }
                if (obj.order === 'ascending') {
                    this.orderBy = obj.prop + ' asc'
                } else if (obj.order === 'descending') {
                    this.orderBy = obj.prop + ' desc'
                } else {
                    this.orderBy = ''
                }
                this.refreshList()
            },
            handleNodeClick(data) {
                if (data.type === '1') {
                    this.searchForm.company.id = data.id
                    this.searchForm.office.id = ''
                } else {
                    this.searchForm.company.id = ''
                    this.searchForm.office.id = data.id
                }
                this.refreshList()
            },
            resetSearch() {
                this.searchForm.company.id = ''
                this.searchForm.office.id = ''
                // this.$refs.officeTree.setCurrentKey(null)
                this.$refs.searchForm.resetFields()
                this.currentChangeHandle(1)
            },
            resetRoleSearch() {
                this.searchForm.company.id = ''
                this.searchForm.office.id = ''
                // this.$refs.officeTree.setCurrentKey(null)
                this.$refs.searchForm.resetFields()
                this.refreshList()
            },
            doSubmit() {
                if (this.limit < this.dataListAllSelections.length) {
                    this.$message.error(`你最多只能选择${this.limit}个用户`)
                    return
                }
                if (this.dataListAllSelections.length == 0) {
                    this.$message.error('请选择用户')
                    return
                }
                this.visible = false
                this.$emit('doSubmit', this.dataListAllSelections)
            }
        }
    }
</script>
